import { Fragment, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import style from "./styles.module.scss";
import { checkLink } from "../../../tools/helpers";
import { useSelector } from "react-redux";

export default function CategoriesBar(props) {
  const container = useRef();
  const { data = [] } = props;
  const [active, setActive] = useState("");
  const token = useSelector((state) => state.authReducer.token);
  
  useEffect(() => {
    Array.from(container.current.children).forEach((children) => {
      if (children.attributes.id.value === active) {
        children.classList.add(style.open);
      } else {
        children.classList.remove(style.open);
        if (active.length) {
          children.classList.add(style.invisible);
        } else {
          children.classList.remove(style.invisible);
        }
      }
    });
  }, [active]);

  return (
    <div className={style.container} ref={container}>
      {data.map((item) => {
        const { isExternal, path } = checkLink(item.path);

        // External links will be displayed only when logged in

        // Check if external
        const linkElement = isExternal ? (
          // Check if logged in
          token.length > 0 && (
            <a href={path} className={style.item} target="_blank" rel="noreferrer">
              {item.label}
            </a>
          )
        ) : (
          // Internal link
          <Link to={path} className={style.item}>
            {item.label}
          </Link>
        );
  
        return linkElement ? (
          <div className={style.itemContainer} id={item.id} key={item.id}>
            <svg
              className={style.back}
              onClick={() => setActive("")}
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              ></path>
            </svg>
            {linkElement}
            {item.children.length ? (
              <Fragment>
                <svg
                  className={style.arrow}
                  onClick={() => setActive(item.id)}
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                  ></path>
                </svg>
                <div className={style.subItemsContainer}>
                  {item.children.map((subItem, index) => (
                    <Fragment key={index}>
                      <Link to={subItem.path} className={style.subItem}>
                        {subItem.label}
                      </Link>
                      {subItem.children.map((child) => (
                        <Link key={index} to={child.path} className={style.subItem}>
                          {child.label}
                        </Link>
                      ))}
                    </Fragment>
                  ))}
                </div>
              </Fragment>
            ) : null}
          </div>
        ) : null;
      })}
    </div>
  );
}
