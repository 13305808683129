import { WEBSITE_URL } from "../config";

export const flatListToHierarchical = (
    data = [],
    {idKey='id',parentKey='parentId',childrenKey='children'} = {}
) => {
    const tree = [];
    const childrenOf = {};
    data.forEach((item) => {
        const newItem = {...item};
        const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
        childrenOf[id] = childrenOf[id] || [];
        newItem[childrenKey] = childrenOf[id];
        parentId
            ? (
                childrenOf[parentId] = childrenOf[parentId] || []
            ).push(newItem)
            : tree.push(newItem);
    });
    return tree;
};

export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
}

export const checkLink = (link) => {

    // Check if link exists and is a string
    if (!link || typeof link !== 'string') {
        return { isExternal: false, path: '/' };
    }

    // Check if it is relative link
    if (link.startsWith('/')) {
        return { isExternal: false, path: link };
    }
    
    // Treat as an internal link if it starts with the base domain
    if (link.startsWith(WEBSITE_URL)) {
        // Strip the base domain, leaving only the relative path
        const path = link.replace(WEBSITE_URL, '');
        return { isExternal: false, path: path || '/' };
    }

    // External link
    return { isExternal: true, path: link };
}